import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import "firebase/firestore";
import "firebase/database";
import { Hero, Show } from '../components';
import { IMAGE_BASE_URL, IMAGE_SIZES, POSTER_PLACEHOLDER, BACKDROP_PLACEHOLDER } from '../constants/constants';
import { useHistory } from 'react-router';

function SectionsMyllist() {
	const history = useHistory();
	const [ result, setResult ] = useState([]);
	const [ arr, setArr ] = useState<any>([])
	const [ activeUser, setActiveUser ] = useState(JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('activeUser')))));
	useEffect(
		()=>{
			firebase.firestore().collection('scare').doc(activeUser._id.toString()).collection('user_list')
			.get().then((data) => {
				arr.length = 0;
				data.forEach((s) => {
					arr.push(s.data().details);
				})
				setResult(arr);
			})
		},[result]
	);
		const windowWidth = window.innerWidth;
		const section = {
			size : 'md'
		}
		const playVideo=(data: any)=>{
			localStorage.setItem('video',JSON.stringify(data));
			history.push('video');
		}
		const deleteVideo=(data: any)=>{
			firebase.firestore().collection('scare').doc(activeUser._id.toString()).collection('user_list').doc(data.id.toString()).delete()
			.then(data=>{
				alert('Delete Successfully');
				window.location.reload();
			});
		}
		return (
			<Show>
				<Show.Card><br></br><br></br><br></br><br></br>
					{(result.length == 0) ? <h2>No video found..</h2> : ''}
							{result.map((data:any)=>{
								return <div>
									<Hero.Button className="white-btn delete-my-list" onClick={ ()=> deleteVideo(data)}>Delete</Hero.Button>
									<Show.Poster
										src={
											section.size === 'lg' ? data.poster_path ? (
												`${IMAGE_BASE_URL +
													(windowWidth <= 600
														? IMAGE_SIZES.poster.small
														: windowWidth <= 1000 ? IMAGE_SIZES.poster.medium : IMAGE_SIZES.poster.large) +
													data.poster_path}`
											) : (
												POSTER_PLACEHOLDER
											) : data.backdrop_path ? (
												`${IMAGE_BASE_URL + IMAGE_SIZES.backdrop.small + data.backdrop_path}`
											) : (
												BACKDROP_PLACEHOLDER
											)
										}
										onClick={()=>playVideo(data)}
										alt={data.name || data.title}
									/>
								</div>
							})}
			</Show.Card>
		</Show>								
		);
}

export default SectionsMyllist;
