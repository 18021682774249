import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { Details, Player, Show } from '../components';
import details from '../components/details';
import { LockBody } from '../components/loading/styles/loading';
import { ShowOverview } from '../constants/types';
import { EpisodeContainer, RecommendationContainer } from '../containers';
import { useHistory } from 'react-router';

type PlayerContainerType = {
	playing: ShowOverview;
	setPlaying: (userDetails?: ShowOverview) => void;
};
function Videopage() {
	
	const [ playing, setPlaying ] = useState(JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('video')))));
	const [ isPaused, setIsPaused ] = useState();
	const history = useHistory();
	const back = () => {
		history.goBack();
	};
	const isHeaderShown = false;
		return playing && (
		<Show>
				<Show.Card><br></br><br></br><br></br><br></br>
		<Details>
			<Player>
				{isPaused && (
					<React.Fragment>
						<Player.Overlay />
						<Player.Details>
							<p>You're watching</p>
							<Player.Title>{playing.name || playing.title || playing.original_name}</Player.Title>
							<p>{playing.overview}</p>
						</Player.Details>
						<p className="paused-text">Paused</p>
					</React.Fragment>
				)}
				<Player.Video setIsPaused={setIsPaused} />
				<Player.Back onClick={() => back()}/>
				<LockBody />
			</Player>
			</Details>
			</Show.Card>
			</Show>
		);
}

export default Videopage;