import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Videojs from '../components/video-player/video';

function Vplayer() {
    const videoJsOptions = {
        autoplay: false,
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        width: 720,
        height: 300,
        controls: true,
        sources: [
          {
            src: 'https://dd2ed745a4864368b9b8faa7bd8211ab.mediatailor.us-east-1.amazonaws.com/v1/master/6774ee7b1064419cc7ad6e7732ea200e01bf7d57/BoTest/No-Ones-There.m3u8',
            // type: 'video/mp4',
          },
        ],
      };
	const history = useHistory();
	const location = useLocation();

	return (
		<Videojs {...videoJsOptions} />
	);
}

export default Vplayer;
