import { Home, Browse, Signin, Signup, List, Videopage, Vplayer } from '../pages';

type Routes = {
	[key: string]: {
		path: string;
		page: JSX.Element;
		isProtected: boolean;
	};
};

export const ROUTES: Routes = {
	HOME: { path: '/', page: <Home />, isProtected: false },
	BROWSE: { path: '/browse', page: <Browse />, isProtected: true },
	SIGNUP: { path: '/signup', page: <Signup />, isProtected: false },
	SIGNIN: { path: '/signin', page: <Signin />, isProtected: false },
	LIST: { path: '/list', page: <List />, isProtected: true },
	Video: { path: '/video', page: <Videopage />, isProtected: true },
	sample: { path: '/sample-video', page: <Vplayer />, isProtected: true },
};
